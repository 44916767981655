import Contacts from "../../components/Contact/Contact";
const Contact = () => {
  return (
    <>
      <div className="py-10 px-4">
        <Contacts />
      </div>
    </>
  );
};

export default Contact;
